import { Component, OnInit, Inject } from "@angular/core";
import { NgForm } from "@angular/forms";
import { AuthService } from "../../services/auth.service";

import { NgxSpinnerService } from "ngx-spinner";

@Component({
  selector: "app-login",
  templateUrl: "./login.component.html",
  //styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  model: FormData = new FormData();

  onLoginClick: boolean = false;
  constructor(
    @Inject(AuthService) private authService: AuthService,
    private spinner: NgxSpinnerService
  ) {}

  ngOnInit() {}

  onSubmit(form: NgForm) {
    this.onLoginClick = true;
    this.spinner.show();
    const email = form.value.email;
    const secretkey = form.value.secretKey;
    this.authService.loginCall(email, secretkey, this.spinner);
  }
}

class FormData {
  constructor(public email: string = "", public secretKey: string = "") {}
}
