import { BrowserModule } from "@angular/platform-browser";
import { NgModule } from "@angular/core";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { LoginComponent } from "./layouts/login/login.component";
import { MainLayoutComponent } from "./layouts/main-layout/main-layout.component";

import { AngularFireModule } from "@angular/fire";
import { environment } from "../environments/environment";
import { AngularFireDatabaseModule } from "@angular/fire/database";
import { AuthService } from "./services/auth.service";
import { SidebarModule } from "./sidebar/sidebar.module";
import { AgmCoreModule } from "@agm/core";
import { DatePipe } from "@angular/common";
import { HttpClientModule } from "@angular/common/http";

import { UserService } from "./services/user.service";

import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { NgxPaginationModule } from "ngx-pagination";
import { SidemenuComponent } from "./sidemenu/sidemenu.component";
import { TermsAndConditionsComponent } from "./terms-and-conditions/terms-and-conditions.component";

import { AuthGuard } from "./services/auth.guard";
import * as firebase from "firebase";

import { AngularFireAuth } from "@angular/fire/auth";

import { MatDialogModule } from "@angular/material/dialog";
import { MatButtonModule } from "@angular/material/button";

import { NgxSpinnerModule } from "ngx-spinner";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";

import { SpinnerComponent } from "./shared/spinner/spinner.component";
import { DeletedUsersComponent } from "./pages/deleted-users/deleted-users.component";
import { DeleteConfirmationDialogComponent } from "./shared/delete-confirmation-dialog/delete-confirmation-dialog.component";
import { RegistrationCompleteDialogComponent } from "./shared/registration-complete-dialog/registration-complete-dialog.component";
import { UserUpdateCompleteDialogComponent } from "./shared/user-update-complete-dialog/user-update-complete-dialog.component";
import { UserExistsDialogComponent } from "./shared/user-exists-dialog/user-exists-dialog.component";
import { MatInputModule } from "@angular/material/input";
import { MatDatepickerModule } from "@angular/material/datepicker";
import { DpDatePickerModule } from "ng2-date-picker";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatNativeDateModule } from "@angular/material/core";
import { MatSelectModule } from "@angular/material/select";
import { DateRangePickerComponent } from "./shared/date-range-picker/date-range-picker.component";
import { MAT_FORM_FIELD_DEFAULT_OPTIONS } from "@angular/material/form-field";
import { OutsideClickDirective } from "./directives/outside-close.directive";
import { SharePopupComponent } from './components/share-popup/share-popup.component';
import { ShareComponent } from './pages/share/share.component';
import { ViewSharedTrackingComponent } from './pages/view-shared-tracking/view-shared-tracking.component';

firebase.initializeApp(environment.firebaseConfig);

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    MainLayoutComponent,
    SidemenuComponent,
    TermsAndConditionsComponent,
    DeleteConfirmationDialogComponent,
    RegistrationCompleteDialogComponent,
    UserUpdateCompleteDialogComponent,
    UserExistsDialogComponent,
    OutsideClickDirective,
    SharePopupComponent,
    ShareComponent,
    ViewSharedTrackingComponent,
  ],
  imports: [
    DpDatePickerModule,
    AngularFireModule.initializeApp(environment.firebaseConfig),
    BrowserModule,
    AppRoutingModule,
    AngularFireDatabaseModule,
    SidebarModule,
    NgbModule,
    FormsModule,
    AgmCoreModule,
    NgxPaginationModule,
    HttpClientModule,
    NgxSpinnerModule,
    BrowserAnimationsModule,
    MatDialogModule,
    MatButtonModule,
    MatSelectModule,
    ReactiveFormsModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatFormFieldModule,
    MatInputModule,
  ],
  entryComponents: [
    DeleteConfirmationDialogComponent,
    RegistrationCompleteDialogComponent,
    UserUpdateCompleteDialogComponent,
    UserExistsDialogComponent,
  ],
  providers: [
    AuthService,
    DatePipe,
    UserService,
    AuthGuard,
    AngularFireAuth,
    {
      provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
      useValue: { appearance: "fill" },
    },
  ],
  bootstrap: [AppComponent],
  exports: [MatDatepickerModule, MatNativeDateModule],
})
export class AppModule {}
