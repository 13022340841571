import { Injectable } from '@angular/core';
import { AngularFireDatabase, AngularFireList } from "@angular/fire/database";
import * as moment from "moment-timezone";
import { DatePipe } from "@angular/common";
import { BehaviorSubject, Subject } from 'rxjs';
import { DataSnapshot } from '@angular/fire/database/interfaces';
import { LastLocation } from '../models/last-location.model';

@Injectable({
  providedIn: 'root'
})
export class SharedTrackingService {

  trackDataList$ = new BehaviorSubject([]);
  keyData$ = new Subject();
  lastLocation: any;

  constructor(
    private firebase: AngularFireDatabase
  ) { }

  async addSharedTrack(share: any) {

    // const dbFromDate = moment(share?.fromDate).format("YYYY-MM-DD");;
    // const dbToDate = moment(share?.toDate).add(2, "days").format("YYYY-MM-DD");

    let tz = moment.tz.setDefault("America/Los_Angeles");
    let currentLTime = moment().valueOf();
    const finalLTime = moment(currentLTime).add(share?.duration, "hours").valueOf();

    console.log(currentLTime, finalLTime);
    return await this.firebase.database
    .ref("shared-tracking")
    .push({
      from: currentLTime,
      to: finalLTime,
      gscNumberList: share?.gscNumberList.map(g => g?.gscId),
      duration: share?.duration,
      durationUnit: "hours"
    })
    .once("value");
  };

  getSharedTrackList() {

    let tz = moment.tz.setDefault("America/Los_Angeles");
    let list = [];
    this.firebase.database.ref("shared-tracking")
    .on("value", (data: DataSnapshot) => {
      data.forEach((child) => {
        let t = child.val();
        let k = child.key;
        let a = moment().isBefore(t?.to); 
        list.push({
          ...t,
          status: a ? "Active" : "Expired",
          gscNumbers: t?.gscNumberList?.toString(),
          key: k
        });
      });

      this.trackDataList$.next(list.reverse());
    });

  };

  getViewTrackList(key: string) {
    this.firebase.database.ref("shared-tracking")
    .child(key)
    .once("value", (data: DataSnapshot) => {
      let o = {
        ...data.val(),
        gscNumbers: data.val()?.gscNumberList?.toString(),
        key: key
      };
      this.keyData$.next(o);
    });

  };

  getSharedData() {
    return this.trackDataList$.asObservable();
  }

  getKeyData() {
    return this.keyData$.asObservable();
  }

  async getLastLocationOfUser() {
    return this.lastLocation;
  }

  async lastLocationOfUser(user: any) {
    return await this.firebase.database
    .ref("last-location")
    .child(user)
    .once("value")
  }
}
