import { DatePipe } from '@angular/common';
import { Injectable } from '@angular/core';
import * as moment from 'moment';
import { Observable, BehaviorSubject } from 'rxjs';
import { ViewShareDataService } from './view-share-data.service';

@Injectable({
  providedIn: 'root'
})
export class ViewShareLogicService {

  constructor(
    private historyMultipleData: ViewShareDataService,
    private datepipe: DatePipe
  ) { }

  getAllUsers() {
    return this.historyMultipleData.getAllUsers();
  }

  async searchClickLogic(value: any): Promise<Observable<any[]>> {
    let allDataList = [];
    let allDataListSubject = new BehaviorSubject<any[]>([]);

    let fromDate = value.fromDate;
    let toDate = value.toDate;
    let fromTime = value.fromTime;
    let toTime = value.toTime;

    // const convertedFromDate = this.datepipe.transform(fromDate, "dd/MM/yy");
    // const convertedToDate = this.datepipe.transform(toDate, "dd/MM/yy");

    const dbFromDate = moment.utc(fromDate, "DD/MM/YY").format("DD-MM-YY");
    const dbToDate = moment.utc(toDate, "DD/MM/YY").format("DD-MM-YY");
    //const dbToDate = moment(toDate).add(2, "days").format("DD-MM-YY");

    const convertedFromTime = moment(fromTime, "hh:mm:ss A").format("HH:mm:ss");
    const convertedToTime = moment(toTime, "hh:mm:ss A").format("HH:mm:ss");

    // const startDate = convertedFromDate + " " + convertedFromTime;
    // const endDate = convertedToDate + " " + convertedToTime;

    const month = moment(fromDate, "DD/MM/YY").format("MMMM-YYYY");

    var dateStart = moment(dbFromDate, "DD/MM/YY");
    var dateEnd = moment(dbToDate, "DD/MM/YY");
    var interim = dateStart.clone();
    var timeValues = [];

    while (dateEnd > interim || interim.format('M') === dateEnd.format('M')) {
      const s = moment(interim).startOf('month').format("DD-MM-YY");
      const e = moment(interim).endOf("month").format("DD-MM-YY");
      const m = moment(interim).startOf('hour').format("MMMM-YYYY");
      timeValues.push({
        month: month,
        fromDate: s,
        toDate: e
      });
      interim.add(1, 'month');
    }

    timeValues[0].fromDate = dbFromDate;
    timeValues[timeValues.length - 1].toDate = dbToDate;

    const fromTimePT = moment
      .tz(fromTime, "hh:mm:ss A", "America/Los_Angeles")
      .format("hh:mm A");

      const fromPT = moment.tz(value.adminFromT, "America/Los_Angeles").utc().format('DD-MM-YY');
      const toPT = moment.tz(value.adminToT, "America/Los_Angeles").utc().format('DD-MM-YY');

      //console.log("pt time ", fromPT, toPT, value.adminFromT, value.adminToT)
    // const fromTimestampPT = moment
    //   .tz(startDate, "America/Los_Angeles")
    //   .format("DD/MM/YY HH:mm:ss");

    // const toTimestampPT = moment
    //   .tz(endDate, "DD/MM/YY HH:mm:ss", "America/Los_Angeles")
    //   .format("DD/MM/YY HH:mm:ss");

    // const fromTimestamp = moment
    //   .utc(fromTimestampPT, "DD/MM/YY HH:mm:ss")
    //   .valueOf();

    // const toTimestamp = moment
    //   .utc(toTimestampPT, "DD/MM/YY HH:mm:ss")
    //   .valueOf();

    value.gscNumberList.forEach(async (gsc) => {
      let filteredSingleList = [];

      for (let tv of timeValues) {
        console.log("time ",  gsc.gscId,
        tv?.month,
        tv?.fromDate,
        tv?.toDate )
        const singleDataList = await this.historyMultipleData.getMonthTrackHistory(
          gsc.gscId,
          tv?.month,
          fromPT,
          toPT
        );
        console.log("time 2", singleDataList )
        if (typeof singleDataList != "undefined" && singleDataList.length != 0) {
          for (let k = 0; k < singleDataList.length; k++) {
            
            const dateToPT = moment
            .utc(singleDataList[k].recordedDate, "DD/MM/YY, hh:mm:ss A")
            .clone()
            .tz(singleDataList[k].timeZoneId)
            .format("DD/MM/YY, hh:mm:ss A");

          singleDataList[k].recordedDate = dateToPT;
          singleDataList[k].adminDate = moment.tz(singleDataList[k].timestamp, "America/Los_Angeles").format("DD-MM-YYYY")
          singleDataList[k].adminTime = moment.tz(singleDataList[k].timestamp, "America/Los_Angeles").format("hh:mm A")
          filteredSingleList.push(singleDataList[k]);
          }
          allDataList.push(filteredSingleList);
          allDataListSubject.next(allDataList);

        }


      }

    });

    return allDataListSubject.asObservable();
  }
}
