import {
  Component,
  OnInit,
  ViewChild,
  ChangeDetectorRef,
  HostListener,
  ElementRef,
  Renderer2,
} from "@angular/core";
import { GoogleMap, Point } from "@agm/core/services/google-maps-types";
import * as firebase from "firebase";
import { ActivatedRoute, Router } from "@angular/router";
import { UserService } from "../../services/user.service";
import { UserTrackDataModel } from "../../models/userTrackData.model";
import { GscIdCheckModel, SearchGscModel } from "../../models/search-gsc.model";
import { google } from "google-maps";

import { } from "googlemaps";
import { AngularFireDatabase } from "@angular/fire/database";
import { DataSnapshot } from "@angular/fire/database/interfaces";
import { DatePipe } from "@angular/common";

import { } from "googlemaps";
import { NgxSpinnerService } from "ngx-spinner";
import { ShareLogic } from "src/app/services/share-logic.service";
import { SharedTrackingService } from "src/app/services/shared-tracking.service";
import { FormControl } from "@angular/forms";
import { BehaviorSubject, Observable } from "rxjs";
import { User } from "src/app/models/user.model";
import * as moment from "moment-timezone";
import { HistoryMultipleLogic } from "src/app/services/history-multiple.logic.service";
import { ViewShareLogicService } from "src/app/services/view-share-logic.service";

declare var google: any;
let map: GoogleMap;

let marker = new google.maps.Marker();
let infowindow: any;
const iconBase = "http://maps.google.com/mapfiles/ms/icons/";

interface TrackHistoryLocation {
  color: any;
  name: string;
  gscNo: string;
  locationName: string;
  latitude: string;
  longitude: string;
  recordedDate: string;
  date: string;
  time: string;
  subLocationName: string;
  timestamp: string;
  key: string;
};

const options = {
  enableHighAccuracy: true,
  timeout: 5000,
  maximumAge: 0,
  gestureHandling: "greedy",
};

@Component({
  selector: 'app-view-shared-tracking',
  templateUrl: './view-shared-tracking.component.html',
  styleUrls: ['./view-shared-tracking.component.scss']
})
export class ViewSharedTrackingComponent implements OnInit {

  @ViewChild("shareMap", { static: false }) mapElement: {
    nativeElement: any;
  };

  userTrackList: UserTrackDataModel[] = [];
  searchGscModel: SearchGscModel;

  latitude: number;
  longitude: number;
  zoom: number;

  locations: TrackHistoryLocation[] = [];
  allLocations: TrackHistoryLocation[][] = [];

  private allMarkers = [];
  private singleGscMarkers = [];
  private coOrdinates: Point[] = [];
  private allCoOrdinates: Point[][] = [];

  private markersArray = [];
  private singleGscMarkersArray = [];
  private pathsArray = [];

  public show_spinner = "show_spinner";

  colors = [];

  allUsers: GscIdCheckModel[];
  filteredUsers: GscIdCheckModel[];
  selectedUsers: GscIdCheckModel[] = [];
  selectedUsersSubject = new BehaviorSubject<GscIdCheckModel[]>([]);
  selectedUsers$: Observable<GscIdCheckModel[]>;

  isSelectedGscClose: boolean = true;
  keyId: string;
  isTrackingExpired: boolean = false;
  trackUsers: any;
  finalUser: any;

  constructor(
    private userService: UserService,
    private router: Router,
    private route: ActivatedRoute,
    private angularFireDatabase: AngularFireDatabase,
    private changeDetector: ChangeDetectorRef,
    private shareLogic: ShareLogic,
    private sharedTrackingLogic: SharedTrackingService,
    private historyMultipleLogic: ViewShareLogicService
  ) { }


  ngOnInit(): void {

    this.keyId = this.route.snapshot.paramMap.get('id');

    this.colors = [
      "#7284A8",
      "#474954",
      "#3590F3",
      "#1C3A13",
      "#004BA8",
      "#EC4E20",
      "#574F2A",
      "#466060",
      "#57886C",
      "#222E50",
      "#007991",
      "#5D536B",
      "#963D5A",
      "#575761",
      "#648381",
      "#653239",
      "#37123C",
      "#00A676",
      "#343E3D",
      "#52B2CF",
      "#13262F",
      "#646F4B",
      "#EA3788",
      "#347FC4",
      "#B0228C",
      "#361134",
      "#D78521",
      "#66462C",
      "#880D1E",
      "#6F8AB7",
      "#615D6C",
    ];

    this.initMap();

    this.shareLogic.getAllUsers().subscribe((value) => {
      this.allUsers = [];
      for (let i = 0; i < value.length; i++) {
        let gscIdCheckModel = new GscIdCheckModel();
        gscIdCheckModel.gscId = value[i];
        gscIdCheckModel.color = this.colors[
          Math.floor(Math.random() * this.colors.length)
        ];
        this.allUsers.push(gscIdCheckModel);
      }
    });

    this.sharedTrackingLogic.getViewTrackList(this.keyId);
    this.sharedTrackingLogic.getKeyData().subscribe(async (m: any) => {

       this.isTrackingExpired = moment().isAfter(m?.to);
       if (this.isTrackingExpired) return;

      this.trackUsers = m;

      let loc: any = await this.sharedTrackingLogic.lastLocationOfUser(m?.gscNumberList[0]);

      loc = loc.val();

      const adminFrom = moment(this.trackUsers?.from).tz("America/Los_Angeles").format("DD-MM-YYYY, hh:mm A");
        const adminTo = moment(this.trackUsers?.to).tz("America/Los_Angeles").format("DD-MM-YYYY, hh:mm A");

        const fromTimestampLocal = moment
        .tz(this.trackUsers?.from, loc?.timeZoneId)
        .valueOf();

        const toTimestampLocal = moment
        .tz(this.trackUsers?.to, loc?.timeZoneId)
        .valueOf()

        const fromLocalFormat = moment.tz(fromTimestampLocal, loc?.timeZoneId).format("DD-MM-YYYY, hh:mm A");
        const toLocalFormat = moment.tz(toTimestampLocal, loc?.timeZoneId).format("DD-MM-YYYY, hh:mm A");

        const fromLocalDate = moment.tz(fromTimestampLocal, loc?.timeZoneId).format("DD/MM/YY");
        const toLocalDate = moment.tz(toTimestampLocal, loc?.timeZoneId).format("DD/MM/YY");

        const fromLocalTime = moment.tz(fromTimestampLocal, loc?.timeZoneId).format("hh:mm:ss A");
        const toLocalTime = moment.tz(toTimestampLocal, loc?.timeZoneId).format("hh:mm:ss A");

      let l = this.trackUsers?.gscNumberList;

      for (let i = 0; i < l.length; i++) {
        let gscIdCheckModel = new GscIdCheckModel();
        gscIdCheckModel.gscId = l[i];
        gscIdCheckModel.color = this.colors[
          Math.floor(Math.random() * this.colors.length)
        ];
        gscIdCheckModel.userFrom = fromLocalFormat;
        gscIdCheckModel.userTo = toLocalFormat;
        gscIdCheckModel.adminFrom = adminFrom;
        gscIdCheckModel.adminTo = adminTo;
        this.selectedUsers.push(gscIdCheckModel);
      }

      this.finalUser = {
        fromDate: fromLocalDate,
        toDate: toLocalDate,
        fromTime: fromLocalTime,
        toTime: toLocalTime,
        adminFromT: this.trackUsers?.from,
        adminToT: this.trackUsers?.to
      }

      this.getHistory(this.finalUser);
      this.loopLocation();

      });


  };

  loopLocation() {
    this.getHistory(this.finalUser);
    setTimeout(() => {
      this.loopLocation();
    }, 60000);
  };

  ngOnDestroy() {
    this.changeDetector.detach();
  }

  initMap() {
    navigator.geolocation.getCurrentPosition(
      (location) => {
        map = new google.maps.Map(this.mapElement.nativeElement, {
          center: {
            lat: location.coords.latitude,
            lng: location.coords.longitude,
          },
          gestureHandling: "greedy",
          zoom: 17,
        });

        infowindow = new google.maps.InfoWindow();
      },
      (error) => {
        console.log(error);
      },
      options
    );
  }

  async getHistory(value: any) {

    if (
      !value.fromDate ||
      !value.toDate ||
      !value.fromTime ||
      !value.toTime ||
      this.selectedUsers.length == 0
    )
      return;

    this.isSelectedGscClose = false;

    value.gscNumberList = this.selectedUsers;

    this.changeDetector.detectChanges();

    this.clearMap();

    // value.fromDate = moment(value.fromDate);
    // value.toDate = moment(value.toDate);

    this.locations = [];
    this.allMarkers = [];
    this.coOrdinates = [];
    this.pathsArray = [];
    this.markersArray = [];

    (await this.historyMultipleLogic.searchClickLogic(value)).subscribe(
      (list) => {
console.log("list ", list)
        if (list.length != 0) {
          for (let i = 0; i < list.length; i++) {
            this.locations = [];

            if (list[i].length != 0) {
              list[i].map((location, j) => {
                if (list[i].length - 1 === j) {
                  location.color = this.selectedUsers.filter((user) => {
                    return user.gscId === location.gscNo;
                  });

                  j === 0
                    ? this.createMarkers(location, true, false)
                    : j === list[i].length - 1
                      ? this.createMarkers(location, false, true)
                      : this.createMarkers(location, false, false);

                  this.locations.push(location);
                  this.allMarkers.push(marker);
                  this.markersArray.push(marker);
                }
              });

              this.allLocations.push(this.locations);
            }
          }

          //this.addPolyline();
          this.fitMarkers();
        }

        let result: [] = list.reduce(
          (accumulator, value) => accumulator.concat(value),
          []
        );
      }
    );
  }

  createMarkers(location, isFirst, isLast) {

    const longitudeF = parseFloat(location.longitude);
    const latitudeF = parseFloat(location.latitude);

    if (isFirst || isLast) {
      var icon = {
        fillColor: `${location.color[0]?.color}`,
        strokeColor: `${location.color[0]?.color}`,
        path: google.maps.SymbolPath.CIRCLE,
        fillOpacity: 1,
        scale: 6,

        anchor: new google.maps.Point(0, 0),
      };

      marker = new google.maps.Marker({
        map,
        position: { lat: latitudeF, lng: longitudeF },
        icon: iconBase + "green-dot.png",
        optimized: false,
      });
    } else {
      marker = new google.maps.Marker({
        map,
        position: { lat: latitudeF, lng: longitudeF },
        icon: "../../../assets/icons/dot.svg",
      });
    }

    if (
      typeof location != "undefined" &&
      typeof location.address != "undefined"
    ) {
      
      google.maps.event.addListener(marker, "mouseover", function () {
        infowindow.setContent(`<main class="info-window">
  <section class="info-window__first">
    <div class="first__color-block" style="background-color:${location.color[0].color};"></div>
    <div class="first__title">${location.gscNo}</div>
  </section>
  <section class="info-window__second">${location.address}</section>
  <section class="info-window__third">
    <div class="third__date-time">${location.date}</div>
    <div class="third__date-time">${location?.recordedDate?.split(', ')[1]}</div>
  </section>
</main>`);
        infowindow.open(map, this);
        //${location?.recordedDate?.split(', ')[1]}
      });
    } else {
      google.maps.event.addListener(marker, "mouseover", function () {
        infowindow.setContent(`<main class="info-window">
  <section class="info-window__first">
    <div class="first__color-block" style="background-color:${location.color[0].color};></div>
    <div class="first__title">${location.gscNo}</div>
  </section>
  <section class="info-window__second">${location.locality}</section>
  <section class="info-window__third">
    <div class="third__date-time">${location.date}</div>
    <div class="third__date-time">${location?.recordedDate?.split(', ')[1]}</div>
  </section>
</main>`);
        infowindow.open(map, this);
      });
    }

    google.maps.event.addListener(marker, "mouseout", function () {
      infowindow.close();
    });
  }

  fitMarkers() {
    var bounds = new google.maps.LatLngBounds();
    for (var i = 0; i < this.allMarkers.length; i++) {
      bounds.extend(this.allMarkers[i].getPosition());
    }

    map.fitBounds(bounds);
  }

  addPolyline() {
    if (this.allLocations.length == 0) return;

    for (let j = 0; j < this.allLocations.length; j++) {
      this.coOrdinates = [];

      let locations = this.allLocations[j];

      for (let i = 0; i < locations.length; i++) {
        const latitude = locations[i].latitude;
        const longitude = locations[i].longitude;

        var point = new google.maps.LatLng(latitude, longitude);
        this.coOrdinates.push(point);
      }

      if (typeof locations[0] == "undefined") return;

      const path = new google.maps.Polyline({
        path: this.coOrdinates,
        strokeColor: locations[0].color[0].color,
        strokeOpacity: 1.0,
        strokeWeight: 2,
      });

      this.pathsArray.push(path);

      path.setMap(map);

      this.allCoOrdinates.push(this.coOrdinates);
    }
  }

  clearMap() {
    for (let marker of this.markersArray) {
      marker.setMap(null);
    }
    for (let path of this.pathsArray) {
      path.setMap(null);
    }

    this.markersArray.length = 0;
    this.pathsArray.length = 0;
  }
}
