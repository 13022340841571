import { Component, OnInit } from "@angular/core";

@Component({
  selector: "app-user-exists-dialog",
  templateUrl: "./user-exists-dialog.component.html",
})
export class UserExistsDialogComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
