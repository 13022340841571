import { Component, OnInit } from "@angular/core";

@Component({
  selector: "delete-confirmation-dialog-cmp",
  templateUrl: "./delete-confirmation-dialog.component.html",
})
export class DeleteConfirmationDialogComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
