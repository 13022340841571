// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  firebaseConfig: {
    apiKey: "AIzaSyAh1g9_CglLAS0zhKhOz2HVaJ7qXPlPcI8",
    authDomain: "gsc-admin-a62cf.firebaseapp.com",
    databaseURL: "https://gsc-admin-a62cf.firebaseio.com",
    projectId: "gsc-admin-a62cf",
    storageBucket: "gsc-admin-a62cf.appspot.com",
    messagingSenderId: "889832463027",
    appId: "1:889832463027:web:f134f9f52fff8e3ea02ec6",
    measurementId: "G-821R6EELB3"

    /* apiKey: "AIzaSyCcinHMgedvQUr9La94pitWwn8BI_JTJ3U",
   authDomain: "fir-d7795.firebaseapp.com",
   databaseURL: "https://fir-d7795.firebaseio.com",
   projectId: "fir-d7795",
   storageBucket: "",
   messagingSenderId: "567936024192",
   appId: "1:567936024192:web:6c39ad187bc2232108b065"*/
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
