import { Component, OnInit } from "@angular/core";
import { AuthService } from "src/app/services/auth.service";

@Component({
  selector: "sidebar-cmp",
  templateUrl: "./sidebar.component.html"
})
export class SidebarComponent implements OnInit {
  constructor(private authService: AuthService) {}
  ngOnInit() {}

  logOut() {
    this.authService.logoutCall();
  }
}
