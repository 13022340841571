import { Injectable } from "@angular/core";
import { ShareData } from "./share-data.service";
import * as moment from "moment-timezone";
import { DatePipe } from "@angular/common";
import { SearchGscModel } from "../models/search-gsc.model";
import { BehaviorSubject, Observable } from "rxjs";
import { HistoryMultipleData } from "./history-multiple.data.service";

@Injectable({ providedIn: "root" })
export class ShareLogic {
  constructor(
    private shareData: ShareData,
    private datepipe: DatePipe,
    private historyMultipleData: HistoryMultipleData
  ) {}

  getAllUsers() {
    return this.shareData.getAllUsers();
  }

  async searchClickLogic(value: SearchGscModel): Promise<Observable<any[]>> {
    let allDataList = [];
    let allDataListSubject = new BehaviorSubject<any[]>([]);

    let fromDate = value.fromDate;
    let toDate = value.toDate;
    let fromTime = value.fromTime;
    let toTime = value.toTime;

    const convertedFromDate = this.datepipe.transform(fromDate, "dd/MM/yy");
    const convertedToDate = this.datepipe.transform(toDate, "dd/MM/yy");

    const dbFromDate = this.datepipe.transform(fromDate, "dd-MM-yy");
    const dbToDate = this.datepipe.transform(toDate, "dd-MM-yy");
    //const dbToDate = moment(toDate).add(2, "days").format("DD-MM-YY");

    const convertedFromTime = moment(fromTime, "hh:mm:ss A").format("HH:mm:ss");
    const convertedToTime = moment(toTime, "hh:mm:ss A").format("HH:mm:ss");

    const startDate = convertedFromDate + " " + convertedFromTime;
    const endDate = convertedToDate + " " + convertedToTime;

    const month = moment(convertedFromDate, "DD/MM/YY").format("MMMM-YYYY");

    var dateStart = moment(fromDate);
    var dateEnd = moment(toDate);
    var interim = dateStart.clone();
    var timeValues = [];

    while (dateEnd > interim || interim.format('M') === dateEnd.format('M')) {
      const s = moment(interim).startOf('month').format("DD-MM-YY");
      const e = moment(interim).endOf("month").format("DD-MM-YY");
      timeValues.push({
        month: interim.format('MMMM-YYYY'),
        fromDate: s,
        toDate: e
      });
      interim.add(1, 'month');
    }

    timeValues[0].fromDate = dbFromDate;
    timeValues[timeValues.length - 1].toDate = dbToDate;

    const fromTimestampPT = moment
      .tz(startDate, "DD/MM/YY HH:mm:ss", "America/Los_Angeles")
      .utc()
      .format("DD/MM/YY HH:mm:ss");

    const toTimestampPT = moment
      .tz(endDate, "DD/MM/YY HH:mm:ss", "America/Los_Angeles")
      .utc()
      .format("DD/MM/YY HH:mm:ss");

    const fromTimestamp = moment
      .utc(fromTimestampPT, "DD/MM/YY HH:mm:ss")
      .valueOf();

    const toTimestamp = moment
      .utc(toTimestampPT, "DD/MM/YY HH:mm:ss")
      .valueOf();

    value.gscNumberList.forEach(async (gsc) => {
      let filteredSingleList = [];

      for (let tv of timeValues) {

        const singleDataList = await this.historyMultipleData.getMonthTrackHistory(
          gsc.gscId,
          tv?.month,
          tv?.fromDate,
          tv?.toDate
        );

        if (typeof singleDataList != "undefined" && singleDataList.length != 0) {
          for (let k = 0; k < singleDataList.length; k++) {
            const timestampActual = +singleDataList[k].timestamp;
            const timestampToCheck = moment
              .utc(timestampActual)
              .isBetween(fromTimestamp, toTimestamp);

            if (timestampToCheck == true) {
              const dateToPT = moment
                .utc(singleDataList[k].recordedDate, "DD/MM/YY, hh:mm:ss A")
                .clone()
                .tz(singleDataList[k].timeZoneId)
                .format("DD/MM/YY, hh:mm:ss A");

              singleDataList[k].recordedDate = dateToPT;

              filteredSingleList.push(singleDataList[k]);

            }
          }
          allDataList.push(filteredSingleList);
          allDataListSubject.next(allDataList);

        }


      }

    });

    return allDataListSubject.asObservable();
  }
}
