import * as firebase from "firebase";
import { Injectable } from "@angular/core";
import { error } from "@angular/compiler/src/util";
import { Router } from "@angular/router";
import { AngularFireAuth } from "@angular/fire/auth";

@Injectable()
export class AuthService {
  userData: any;

  [x: string]: any;
  constructor(private router: Router, public angularFireAuth: AngularFireAuth) {
    this.angularFireAuth.authState.subscribe(user => {
      if (user) {
        this.userData = user;
        localStorage.setItem("user-data", JSON.stringify(user));
      } else {
        localStorage.setItem("user-data", null);
      }
    });
  }

  loginCall(email: string, secretKey: string, spinner: any) {
    this.angularFireAuth.auth
      .signInWithEmailAndPassword(email, secretKey)
      .then(result => {
        spinner.hide();
        this.router.navigate(["dashboard"]);
      })
      .catch(error => {
        spinner.hide();
        alert("Invalid User ID or Password");
        // Include for resetting form entries
      });
  }

  get isLoggedIn(): boolean {
    const user = JSON.parse(localStorage.getItem("user-data"));
    return user !== null ? true : false;
  }

  homeCall(event) {
    this.router.navigate(["dashboard"]);
  }

  logoutCall() {
    return this.angularFireAuth.auth.signOut().then(() => {
      localStorage.removeItem("user-data");
      this.router.navigate(["login"]);
    });
  }

  registerCall(event) {
    this.router.navigate(["dashboard"]);
  }
  detailsCall(event) {
    this.router.navigate(["user-list"]);
  }
}
