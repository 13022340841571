import { Component, OnInit } from "@angular/core";

@Component({
  selector: "user-update-complete-dialog-cmp",
  templateUrl: "./user-update-complete-dialog.component.html",
})
export class UserUpdateCompleteDialogComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
