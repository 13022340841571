import { Injectable } from '@angular/core';
import { AngularFireDatabase } from '@angular/fire/database';
import { DataSnapshot } from 'firebase-functions/v1/database';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ViewShareDataService {

  allGscNumbersSubject = new BehaviorSubject<string[]>([]);
  constructor(private angularFireDatabase: AngularFireDatabase) { }

  getAllUsers() {
    let allGscNumbers = [];
    this.allGscNumbersSubject.next([]);

    this.angularFireDatabase.database
      .ref("registration")
      .orderByChild("memberCard")
      .once("value", (data: DataSnapshot) => {
        if (data.exists()) {
          data.forEach((child: DataSnapshot) => {
            allGscNumbers.push(child.val().memberCard);
          });
          this.allGscNumbersSubject.next(allGscNumbers);
        }
      });

    this.angularFireDatabase.database
      .ref("deleted-users")
      .orderByChild("memberCard")
      .once("value", (data: DataSnapshot) => {
        data.forEach((child: DataSnapshot) => {
          allGscNumbers.push(child.val().memberCard);
        });
        this.allGscNumbersSubject.next(allGscNumbers);
      });

    return this.allGscNumbersSubject.asObservable();
  }

  async getMonthTrackHistory(
    gscNumber: string,
    month: string,
    dbFromDate: string,
    dbToDate: string
  ) {
    console.log("dbdate",dbToDate.trim())
    const trackData = await this.angularFireDatabase.database
      .ref("track-history")
      .child(gscNumber)
      .child(month)
      .orderByKey()
      .startAt(dbFromDate)
      .endAt(dbToDate)
      .once("value");
      
    // "value",
    //   (data: DataSnapshot) => {
    //     // console.log("called here 2 ", gscNumber, month, dbFromDate, dbToDate);
    //     // this.isSearchClicked = false;
    //     // this.changeDetector.detectChanges();
    //     let trackDataArray = [];
    //     data.forEach((child: DataSnapshot) => {
    //       console.log("called here 3 ", child.val());
    //       trackDataArray.push(child.val() as TrackDataModel[]);
    //       return trackDataArray;
    //     });
    //   };
    let trackDataList = [];
    console.log("track data ", trackData)
    trackData.forEach((child) => {
      console.log("track data ", child.val(), child.exists())
      if (child.exists()) {
        let trackHistory = child.val();
       
        Object.keys(trackHistory).map((value) => {
          trackDataList.push(trackHistory[value]);
        });
      }
    });
    return trackDataList;
  }
}
