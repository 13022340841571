import {
  Component,
  OnInit,
  ViewChild,
  ChangeDetectorRef,
  HostListener,
  ElementRef,
  Renderer2,
} from "@angular/core";
import * as firebase from "firebase";
import { Router } from "@angular/router";
import { UserService } from "../../services/user.service";
import { UserTrackDataModel } from "../../models/userTrackData.model";
import { GscIdCheckModel, SearchGscModel } from "../../models/search-gsc.model";
import { google } from "google-maps";

import { } from "googlemaps";
import { AngularFireDatabase } from "@angular/fire/database";
import { DataSnapshot } from "@angular/fire/database/interfaces";
import { DatePipe } from "@angular/common";

import { } from "googlemaps";
import { GoogleMap, Point } from "@agm/core/services/google-maps-types";
import { NgxSpinnerService } from "ngx-spinner";
import { ShareLogic } from "src/app/services/share-logic.service";
import { SharedTrackingService } from "src/app/services/shared-tracking.service";
import { FormControl } from "@angular/forms";
import { BehaviorSubject, Observable } from "rxjs";
import { User } from "src/app/models/user.model";
import * as moment from "moment-timezone";
import { DatePickerComponent } from "ng2-date-picker";
import { MatFormFieldControl } from "@angular/material/form-field";
import { Clipboard } from '@angular/cdk/clipboard';

import {
  MomentDateAdapter,
  MAT_MOMENT_DATE_ADAPTER_OPTIONS,
} from "@angular/material-moment-adapter";

import {
  DateAdapter,
  MAT_DATE_FORMATS,
  MAT_DATE_LOCALE,
} from "@angular/material/core";

import * as _moment from "moment";
import { type } from "os";
import { user } from "firebase-functions/lib/providers/auth";

declare var google: any;
let map: GoogleMap;

let marker = new google.maps.Marker();

const options = {
  enableHighAccuracy: true,
  timeout: 5000,
  maximumAge: 0,
  gestureHandling: "greedy",
};
let infowindow: any;
const iconBase = "http://maps.google.com/mapfiles/ms/icons/";

export const snapshotToArray = (snapshot: any) => {
  const returnArr = [];

  snapshot.forEach((childSnapshot: any) => {
    const item = childSnapshot.val();
    item.key = childSnapshot.key;
    returnArr.push(item);
  });

  return returnArr;
};

interface TrackHistoryLocation {
  color: any;
  name: string;
  gscNo: string;
  locationName: string;
  latitude: string;
  longitude: string;
  recordedDate: string;
  date: string;
  time: string;
  subLocationName: string;
  timestamp: string;
  key: string;
}

export const MY_FORMATS = {
  parse: {
    dateInput: "L",
  },
  display: {
    dateInput: "L",
    monthYearLabel: "MMM YYYY",
    dateA11yLabel: "l",
    monthYearA11yLabel: "DD/MM/YYYY",
  },
};

@Component({
  selector: 'app-share',
  templateUrl: './share.component.html',
  styleUrls: ['./share.component.scss']
})
export class ShareComponent implements OnInit {

  @ViewChild("dropDown", { static: false }) dropDown: {
    nativeElement: any;
  };
  @ViewChild("historyMap", { static: false }) mapElement: {
    nativeElement: any;
  };

  @HostListener("click", ["$event"]) onClick(e: MouseEvent) {
    if (typeof this.dropDown == "undefined") return;

    if (!this.dropDown.nativeElement.contains(e.target)) {
      this.gscNumber = "";
    }
  }

  userTrackList: UserTrackDataModel[] = [];
  searchGscModel: SearchGscModel;

  gscNumber: string;

  p: number = 1;
  page: number = 1;
  pageSize: number = 20;

  isReset: boolean = false;
  isReadOnly: boolean = false;
  message: string;
  isFirstResult: boolean = false;

  latitude: number;
  longitude: number;
  zoom: number;

  locations: TrackHistoryLocation[] = [];
  allLocations: TrackHistoryLocation[][] = [];
  map: any;

  isSearchClicked: boolean = false;

  private allMarkers = [];
  private singleGscMarkers = [];
  private coOrdinates: Point[] = [];
  private allCoOrdinates: Point[][] = [];

  private markersArray = [];
  private singleGscMarkersArray = [];
  private pathsArray = [];

  public show_spinner = "show_spinner";

  colors = [];

  allUsers: GscIdCheckModel[];
  filteredUsers: GscIdCheckModel[];
  selectedUsers: GscIdCheckModel[] = [];
  selectedUsersSubject = new BehaviorSubject<GscIdCheckModel[]>([]);
  selectedUsers$: Observable<GscIdCheckModel[]>;

  isSelectedGscClose: boolean = true;
  sharedLinksList: any[] = [];

  minDate: any = new Date(2023, 0, 3);;
  shareList$: Observable<any>;
  selectedHours: string = "";

  // memberCard: string;
  constructor(
    private userService: UserService,
    private router: Router,
    private angularFireDatabase: AngularFireDatabase,
    private changeDetector: ChangeDetectorRef,
    private shareLogic: ShareLogic,
    private sharedTrackingLogic: SharedTrackingService,
    private clipboard: Clipboard
  ) { }

  ngOnDestroy() {
    this.changeDetector.detach();
  }

  ngOnInit() {
    this.message = "Please select atleast one GSC Member card to generate link!";

    this.colors = [
      "#7284A8",
      "#474954",
      "#3590F3",
      "#1C3A13",
      "#004BA8",
      "#EC4E20",
      "#574F2A",
      "#466060",
      "#57886C",
      "#222E50",
      "#007991",
      "#5D536B",
      "#963D5A",
      "#575761",
      "#648381",
      "#653239",
      "#37123C",
      "#00A676",
      "#343E3D",
      "#52B2CF",
      "#13262F",
      "#646F4B",
      "#EA3788",
      "#347FC4",
      "#B0228C",
      "#361134",
      "#D78521",
      "#66462C",
      "#880D1E",
      "#6F8AB7",
      "#615D6C",
    ];

    this.initMap();

    this.shareLogic.getAllUsers().subscribe((value) => {
      this.allUsers = [];
      for (let i = 0; i < value.length; i++) {
        let gscIdCheckModel = new GscIdCheckModel();
        gscIdCheckModel.gscId = value[i];
        gscIdCheckModel.color = this.colors[
          Math.floor(Math.random() * this.colors.length)
        ];
        this.allUsers.push(gscIdCheckModel);
      }
    });

    this.sharedTrackingLogic.getSharedTrackList();
    this.shareList$ = this.sharedTrackingLogic.getSharedData();
  }

  onIdInput(value) {
    this.filteredUsers = this.allUsers.filter((user) => {
      return user?.gscId?.includes(value);
    });
  }

  onInputCloseClick() {
    this.gscNumber = "";
  }

  copyLink(v: any) {
    this.clipboard.copy(`http://thegscapp.com/tracking/${v?.key}`);
  };

    onGscIdSelect(_user: GscIdCheckModel, isChecked: boolean) {
      if (typeof isChecked == "undefined") return;

      this.selectedUsers = [];

      this.allUsers = this.allUsers.map((user) => {

        user.isChecked = false;

        if (user.gscId == _user.gscId) {
          user.isChecked = isChecked;

          if (isChecked) {
            this.selectedUsers.push(user);
          } else {
            this.selectedUsers = this.selectedUsers.filter(
              (selectedUser) => selectedUser.gscId != user.gscId
            );
          }
        }
        return user;
      });

      this.selectedUsersSubject.next(this.selectedUsers);
      this.selectedUsers$ = this.selectedUsersSubject.asObservable();
    }

    onSelectedGscCloseClick(_user: GscIdCheckModel) {
      this.allUsers = this.allUsers.map((user) => {
        if (user.gscId == _user.gscId) {
          user.isChecked = false;
          this.selectedUsers = this.selectedUsers.filter(
            (selectedUser) => selectedUser.gscId != user.gscId
          );
          this.selectedUsersSubject.next(this.selectedUsers);
        }
        return user;
      });
    }

    onHoursChange(e: any) {
      this.selectedHours = e.target.value;
    };

    async onGenerateLink(value: any) {
      if (
        !this.selectedHours ||
        this.selectedUsers.length == 0
      )
      return;

      // this.isReset = true;
      // this.isReadOnly = true;

      value.duration = +this.selectedHours;

      this.isSelectedGscClose = false;

      value.gscNumberList = this.selectedUsers;

      this.isSearchClicked = true;

      this.sharedTrackingLogic.addSharedTrack(value);
    }

    onResetClick() {
      this.isReset = false;
      this.isReadOnly = false;

      this.selectedUsers = [];
      this.selectedUsersSubject.next([]);

      this.clearMap();

      this.locations = [];
      this.allMarkers = [];
      this.coOrdinates = [];
      this.pathsArray = [];
      this.markersArray = [];

      this.allUsers.forEach((user) => {
        user.isChecked = false;
      });
    }

    initMap() {
      // navigator.geolocation.getCurrentPosition(
      //   (location) => {
      //     map = new google.maps.Map(this.mapElement.nativeElement, {
      //       center: {
      //         lat: location.coords.latitude,
      //         lng: location.coords.longitude,
      //       },
      //       gestureHandling: "greedy",
      //       zoom: 17,
      //     });

      //     infowindow = new google.maps.InfoWindow();
      //   },
      //   (error) => {
      //     console.log(error);
      //   },
      //   options
      // );
    }

    createMarkers(location, isFirst, isLast) {
      this.isSearchClicked = false;
      const longitudeF = parseFloat(location.longitude);
      const latitudeF = parseFloat(location.latitude);

      if (isFirst || isLast) {
        var icon = {
          fillColor: `${location.color[0]?.color}`,
          strokeColor: `${location.color[0]?.color}`,
          path: google.maps.SymbolPath.CIRCLE,
          fillOpacity: 1,
          scale: 6,

          anchor: new google.maps.Point(0, 0),
        };

        marker = new google.maps.Marker({
          map,
          position: { lat: latitudeF, lng: longitudeF },
          icon: icon,
          optimized: false,
        });
      } else {
        marker = new google.maps.Marker({
          map,
          position: { lat: latitudeF, lng: longitudeF },
          icon: "../../../assets/icons/dot.svg",
        });
      }

      if (
        typeof location != "undefined" &&
        typeof location.address != "undefined"
      ) {
        google.maps.event.addListener(marker, "mouseover", function () {
          infowindow.setContent(`<main class="info-window">
                                <section class="info-window__first">
                                <div class="first__color-block" style="background-color:${location.color[0].color};"></div>
                                <div class="first__title">${location.gscNo}</div>
                                </section>
                                <section class="info-window__second">${location.address}</section>
                                <section class="info-window__third">
                                <div class="third__date-time">${location.date}</div>
                                <div class="third__date-time">${location?.recordedDate?.split(', ')[1]}</div>
                                </section>
                                </main>`);
                                infowindow.open(map, this);
        });
      } else {
        google.maps.event.addListener(marker, "mouseover", function () {
          infowindow.setContent(`<main class="info-window">
                                <section class="info-window__first">
                                <div class="first__color-block" style="background-color:${location.color[0].color};></div>
                                <div class="first__title">${location.gscNo}</div>
                                </section>
                                <section class="info-window__second">${location.locality}</section>
                                <section class="info-window__third">
                                <div class="third__date-time">${location.date}</div>
                                <div class="third__date-time">${location?.recordedDate?.split(', ')[1]}</div>
                                </section>
                                </main>`);
                                infowindow.open(map, this);
        });
      }

      google.maps.event.addListener(marker, "mouseout", function () {
        infowindow.close();
      });
    }

    fitMarkers() {
      var bounds = new google.maps.LatLngBounds();
      for (var i = 0; i < this.allMarkers.length; i++) {
        bounds.extend(this.allMarkers[i].getPosition());
      }

      map.fitBounds(bounds);
    }

    addPolyline() {
      if (this.allLocations.length == 0) return;

      for (let j = 0; j < this.allLocations.length; j++) {
        this.coOrdinates = [];

        let locations = this.allLocations[j];

        for (let i = 0; i < locations.length; i++) {
          const latitude = locations[i].latitude;
          const longitude = locations[i].longitude;

          var point = new google.maps.LatLng(latitude, longitude);
          this.coOrdinates.push(point);
        }

        if (typeof locations[0] == "undefined") return;

        const path = new google.maps.Polyline({
          path: this.coOrdinates,
          strokeColor: locations[0].color[0].color,
          strokeOpacity: 1.0,
          strokeWeight: 2,
        });

        this.message = "Please select atleast one GSC Member card to generate link!";

        this.pathsArray.push(path);

        path.setMap(map);

        this.allCoOrdinates.push(this.coOrdinates);
      }
    }

    clearMap() {
      for (let marker of this.markersArray) {
        marker.setMap(null);
      }
      for (let path of this.pathsArray) {
        path.setMap(null);
      }

      this.markersArray.length = 0;
      this.pathsArray.length = 0;
    }

}
