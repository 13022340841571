import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { LoginComponent } from "./layouts/login/login.component";
import { TermsAndConditionsComponent } from "./terms-and-conditions/terms-and-conditions.component";
import { MainLayoutComponent } from "./layouts/main-layout/main-layout.component";
import { ViewSharedTrackingComponent } from "./pages/view-shared-tracking/view-shared-tracking.component";
import { AuthGuard } from "./services/auth.guard";

const routes: Routes = [
  {
    path: "",
    redirectTo: "login",
    pathMatch: "full"
  },
  {
    path: "login",
    component: LoginComponent
  },
  {
    path: "",
    component: MainLayoutComponent,
    children: [
      {
        path: "",
        loadChildren:
          "./layouts/main-layout/main-layout.module#MainLayoutModule"
      }
    ],
    canActivate: [AuthGuard]
  },
  {
    path: "terms-and-conditions",
    component: TermsAndConditionsComponent
  },
  {
    path: "tracking/:id",
    component: ViewSharedTrackingComponent
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],

  exports: [RouterModule]
})
export class AppRoutingModule {}
