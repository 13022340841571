export class SearchGscModel {
  gscNumber: string | number | boolean;
  fromDate: string;
  toDate: string;
  fromTime: string;
  toTime: string;
  gscNumberList: GscIdCheckModel[];
}

export class GscIdCheckModel {
  gscId: string;
  isChecked: boolean = false;
  color: string;
  from?: string;
  to?: string;
  userFrom?: string;
  userTo?: string;
  adminFrom?: string;
  adminTo?: string;
}
