export class User {
  $key: string;
  firstName: string;
  lastName: string;
  mobileNo: string;
  password: string;
  emailID: string;
  memberCard: string;
  confirmMC: string;
}
