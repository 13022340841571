import { Injectable } from "@angular/core";
import { AngularFireDatabase, AngularFireList } from "@angular/fire/database";
import { User } from "../models/user.model";
import { HttpClient } from "@angular/common/http";
import * as moment from "moment-timezone";
import { DataSnapshot } from "@angular/fire/database/interfaces";
import { UserExistsDialogComponent } from "../shared/user-exists-dialog/user-exists-dialog.component";
import { MatDialog } from "@angular/material/dialog";
import { Router } from "@angular/router";

@Injectable({
  providedIn: "root",
})
export class UserService {
  userList: AngularFireList<any>;
  lastLocationList: AngularFireList<any>;
  deletedUserList: AngularFireList<any>;
  userTrackDataList: AngularFireList<any>;

  selectedUser: User = new User();
  firstName: string;
  lastName: string;
  mobileNo: string;
  password: string;
  emailID: string;
  memberCard: string;
  confirmMC: string;
  userkey: any;
  constructor(
    private firebase: AngularFireDatabase,
    private http: HttpClient,
    private dialog: MatDialog,
    private router: Router
  ) {}

  getData() {
    this.userList = this.firebase.list("registration");
    return this.userList;
  }

  getTrackData() {
    this.userTrackDataList = this.firebase.list("trackdata");
    return this.userTrackDataList;
  }

  async addUser(user: User) {
    return await this.firebase.database
      .ref("track-history")
      .child(user.memberCard)
      .once("value");
  }

  newUserRegistration(user: User) {
    this.userList.push({
      firstName: "",
      lastName: "",
      mobileNo: "",
      password: "",
      emailID: user.emailID,
      memberCard: user.memberCard,
      confirmMC: "",
      status: "Registration Incomplete",
    });
  }
  updateUser(user: User, confirmCard: String) {
    let firstName = user.firstName;
    let lastName = user.lastName;
    let mobileNo = user.mobileNo;
    let emailID = user.emailID;
    let memberCard = user.memberCard;
    let confirmCardNumber = confirmCard;

    if (typeof firstName == "undefined" || firstName == "") {
      firstName = "";
    }

    if (typeof lastName == "undefined" || lastName == "") {
      lastName = "";
    }

    if (typeof mobileNo == "undefined" || mobileNo == "") {
      mobileNo = "";
    }

    if (confirmCardNumber == "") {
      confirmCardNumber == "";
    } else {
      confirmCardNumber = memberCard;
    }
    this.userList.update(user.$key, {
      firstName: firstName,
      lastName: lastName,
      mobileNo: mobileNo,
      emailID: emailID,
      memberCard: memberCard,
      confirmMC: confirmCardNumber,
    });
  }

  /* updateUser(user: User) {
    console.log(user.firstName);
    this.userList.update(user.$key, {
      firstName: user.firstName,
      lastName: user.lastName,
      mobileNo: user.mobileNo,
      password: user.password,
      emailID: user.emailID,
      memberCard: user.memberCard,
      confirmMC: user.confirmMC
    });
  }*/

  getDeletedUsersData() {
    this.deletedUserList = this.firebase.list("deleted-users");
    return this.deletedUserList;
  }

  deleteUser(user: User) {
    const currentDate = moment().tz("America/Los_Angeles").format("DD/MM/YY");

    const currentTime = moment().tz("America/Los_Angeles").format("HH:mm:ss");

    this.deletedUserList = this.firebase.list("deleted-users");

    this.deletedUserList.push({
      firstName: user.firstName,
      lastName: user.lastName,
      mobileNo: user.mobileNo,
      emailID: user.emailID,
      memberCard: user.memberCard,
      confirmMC: user.memberCard,
      status: "deleted",
      date: currentDate,
      time: currentTime,
    });

    this.userList.remove(user.$key);

    this.lastLocationList = this.firebase.list("last-location");
    this.lastLocationList.remove(user.memberCard);
  }
  // getUserById(uid) {
  //   return this.db
  //     .collection("registration")
  //     .doc(uid)
  //     .snapshotChanges();
  // }

  sendMail(url, data) {
    return this.http.post(url, data);
  }

  userExistsCallback(exists) {
    if (exists) {
      alert(
        "This GSC Card number already exists. Please use unique number for registration!"
      );
    }
  }
}
