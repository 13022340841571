import { ValueConverter } from "@angular/compiler/src/render3/view/template";
import { Inject, Injectable } from "@angular/core";
import { AngularFireDatabase } from "@angular/fire/database";
import { DataSnapshot } from "@angular/fire/database/interfaces";
import { Subject, BehaviorSubject } from "rxjs";
import { User } from "../models/user.model";
import {
  TrackDataModel,
  UserTrackDataModel,
} from "../models/userTrackData.model";

@Injectable({ providedIn: "root" })
export class ShareData {
  allGscNumbersSubject = new BehaviorSubject<string[]>([]);
  constructor(private angularFireDatabase: AngularFireDatabase) {}

  getAllUsers() {
    let allGscNumbers = [];
    this.allGscNumbersSubject.next([]);

    this.angularFireDatabase.database
    .ref("registration")
    .orderByChild("memberCard")
    .once("value", (data: DataSnapshot) => {
      if (data.exists()) {
        data.forEach((child: DataSnapshot) => {
          allGscNumbers.push(child.val().memberCard);
        });
        this.allGscNumbersSubject.next(allGscNumbers);
      }
    });

    this.angularFireDatabase.database
    .ref("deleted-users")
    .orderByChild("memberCard")
    .once("value", (data: DataSnapshot) => {
      data.forEach((child: DataSnapshot) => {
        allGscNumbers.push(child.val().memberCard);
      });
      this.allGscNumbersSubject.next(allGscNumbers);
    });

    return this.allGscNumbersSubject.asObservable();
  }

  async getMonthTrackHistory(
    gscNumber: string,
    month: string,
    dbFromDate: string,
    dbToDate: string
  ) {

    let trackDataList = new BehaviorSubject([]);
    let list = [];

    await this.angularFireDatabase.database
    .ref("track-history")
    .child(gscNumber)
    .child(month)
    .orderByKey()
    .startAt(dbFromDate)
    .endAt(dbToDate)
    .on("value", (data: DataSnapshot) => {
      trackDataList.next([]);
      data.forEach((child) => {
        let trackHistory = child.val();
        Object.keys(trackHistory).map((value) => {
          list.push(trackHistory[value]);
        });
      });
      trackDataList.next(list);
    });

      return trackDataList.asObservable();
  }
}
