import { Component, OnInit } from "@angular/core";

@Component({
  selector: "registration-complete-dialog-cmp",
  templateUrl: "./registration-complete-dialog.component.html",
})
export class RegistrationCompleteDialogComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
